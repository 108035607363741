// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.our-clients{
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 50px 0px;
    gap: 40px;
}

.client {
    border: 1px solid rgba(0, 0, 0, 0.253);
}`, "",{"version":3,"sources":["webpack://./src/components/OurClients.css"],"names":[],"mappings":"AAAA;IAEI,WAAW;IACX,aAAa;IACb,8BAA8B;IAC9B,iBAAiB;IACjB,SAAS;AACb;;AAEA;IACI,sCAAsC;AAC1C","sourcesContent":[".our-clients{\n    display: flex;\n    width: 100%;\n    display: flex;\n    justify-content: space-between;\n    padding: 50px 0px;\n    gap: 40px;\n}\n\n.client {\n    border: 1px solid rgba(0, 0, 0, 0.253);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
