// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-container{
    transition: transform 0.3s ease-in-out;
    height: auto;
    z-index: 0;
    overflow: hidden;
    justify-self: center;
    &:hover{
        transform: scale(1.1);
    }
}
.image {
    max-width: 100%;
    width: 20rem;
}

.img-text {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 50%;
    background-color: rgba(0, 0, 0, 1); /* Adjust opacity as needed */
    color: white;
    transform: translateY(100%);
    padding: 10px;
    transition: transform 0.3s ease-in-out;
}

.image-container:hover .img-text {
    transform: translateY(0);
    z-index: 1;
}`, "",{"version":3,"sources":["webpack://./src/components/Image.css"],"names":[],"mappings":"AAAA;IACI,sCAAsC;IACtC,YAAY;IACZ,UAAU;IACV,gBAAgB;IAChB,oBAAoB;IACpB;QACI,qBAAqB;IACzB;AACJ;AACA;IACI,eAAe;IACf,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,OAAO;IACP,UAAU;IACV,kCAAkC,EAAE,6BAA6B;IACjE,YAAY;IACZ,2BAA2B;IAC3B,aAAa;IACb,sCAAsC;AAC1C;;AAEA;IACI,wBAAwB;IACxB,UAAU;AACd","sourcesContent":[".image-container{\n    transition: transform 0.3s ease-in-out;\n    height: auto;\n    z-index: 0;\n    overflow: hidden;\n    justify-self: center;\n    &:hover{\n        transform: scale(1.1);\n    }\n}\n.image {\n    max-width: 100%;\n    width: 20rem;\n}\n\n.img-text {\n    position: absolute;\n    bottom: 0;\n    left: 0;\n    width: 50%;\n    background-color: rgba(0, 0, 0, 1); /* Adjust opacity as needed */\n    color: white;\n    transform: translateY(100%);\n    padding: 10px;\n    transition: transform 0.3s ease-in-out;\n}\n\n.image-container:hover .img-text {\n    transform: translateY(0);\n    z-index: 1;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
