// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.showcase2-container {
    display: inline-flex;
    width: 100%;
    justify-content: center;
}

.example-img {
    width: 9rem;
}

.mini-container {
    margin-left: 1.5rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
}

.product p {
    margin-bottom: 0;
}

.product {
    padding-bottom: 30px;
    border-bottom: 2px solid black;
}`, "",{"version":3,"sources":["webpack://./src/components/Showcase2.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,WAAW;IACX,uBAAuB;AAC3B;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,mBAAmB;IACnB,aAAa;IACb,8BAA8B;IAC9B,cAAc;AAClB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,oBAAoB;IACpB,8BAA8B;AAClC","sourcesContent":[".showcase2-container {\n    display: inline-flex;\n    width: 100%;\n    justify-content: center;\n}\n\n.example-img {\n    width: 9rem;\n}\n\n.mini-container {\n    margin-left: 1.5rem;\n    display: grid;\n    grid-template-columns: 1fr 1fr;\n    grid-gap: 1rem;\n}\n\n.product p {\n    margin-bottom: 0;\n}\n\n.product {\n    padding-bottom: 30px;\n    border-bottom: 2px solid black;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
