// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  text-align: center;
  font-family: 'Times New Roman', Times, serif;
}

.component-title {
  font-size: 1.5rem;
}

.component-title::after {
  content: '';
  position: absolute;
  left: 50%;
  bottom: -20px;
  margin-left: -20px;
  width: 40px;
  height: 2px;
  background: rgb(52, 52, 52);
  ;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,4CAA4C;AAC9C;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,SAAS;EACT,aAAa;EACb,kBAAkB;EAClB,WAAW;EACX,WAAW;EACX,2BAA2B;;AAE7B","sourcesContent":[".App {\n  text-align: center;\n  font-family: 'Times New Roman', Times, serif;\n}\n\n.component-title {\n  font-size: 1.5rem;\n}\n\n.component-title::after {\n  content: '';\n  position: absolute;\n  left: 50%;\n  bottom: -20px;\n  margin-left: -20px;\n  width: 40px;\n  height: 2px;\n  background: rgb(52, 52, 52);\n  ;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
