// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.item-block {
    width: 30%;
    border: 2px solid #e5e5e5;
    padding: 30px;
    display: flex;
    align-items: center;
    line-height: 1.4;
    height:10rem;
}`, "",{"version":3,"sources":["webpack://./src/components/Item.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,yBAAyB;IACzB,aAAa;IACb,aAAa;IACb,mBAAmB;IACnB,gBAAgB;IAChB,YAAY;AAChB","sourcesContent":[".item-block {\n    width: 30%;\n    border: 2px solid #e5e5e5;\n    padding: 30px;\n    display: flex;\n    align-items: center;\n    line-height: 1.4;\n    height:10rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
