// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.banner-container {
    position:relative;
    top: 0;
    left: -15%;
    width: 130%;
    object-fit: cover;
    background: black;
}

.banner-image {
    width: 100%;
    opacity: 0.5;
}

.banner-info {
    display: flex;
    position: absolute;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    bottom: 35%;
}

.banner-info p {
    font-size: 1.1rem;
}

.banner-info button {
    background-color: white;
    padding: 10px 20px 10px 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/Banner.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,MAAM;IACN,UAAU;IACV,WAAW;IACX,iBAAiB;IACjB,iBAAiB;AACrB;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,kBAAkB;IAClB,mBAAmB;IACnB,eAAe;IACf,uBAAuB;IACvB,WAAW;AACf;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,uBAAuB;IACvB,4BAA4B;AAChC","sourcesContent":[".banner-container {\n    position:relative;\n    top: 0;\n    left: -15%;\n    width: 130%;\n    object-fit: cover;\n    background: black;\n}\n\n.banner-image {\n    width: 100%;\n    opacity: 0.5;\n}\n\n.banner-info {\n    display: flex;\n    position: absolute;\n    align-items: center;\n    flex-wrap: wrap;\n    justify-content: center;\n    bottom: 35%;\n}\n\n.banner-info p {\n    font-size: 1.1rem;\n}\n\n.banner-info button {\n    background-color: white;\n    padding: 10px 20px 10px 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
