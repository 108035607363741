// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.carousel-container {
    display: flex;
}
.placeholder-img {
    display: flex;
    width: 100%;
    height: 500px;
}

.carousel-control-prev-icon, .carousel-control-next-icon {
    background-color: black;
    padding: 30px;
    opacity: 1 !important;
    filter: invert(1) grayscale(100);
}

.carousel-caption {
bottom: 11rem !important;
}

.carousel-title, .carousel-text {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}

.carousel-title{
    text-transform: uppercase;
    margin-left: 20%;
    margin-right: 20%;
    margin-bottom: 0 !important;
    background-color: rgba(0, 0, 0, .5);
}
.carousel-text {
    font-size: large;
    margin-left: 10%;
    margin-right: 10%;
    height: 2.5rem;
    background-color: rgb(177, 177, 177)
}`, "",{"version":3,"sources":["webpack://./src/components/ExampleCarousel.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;AACA;IACI,aAAa;IACb,WAAW;IACX,aAAa;AACjB;;AAEA;IACI,uBAAuB;IACvB,aAAa;IACb,qBAAqB;IACrB,gCAAgC;AACpC;;AAEA;AACA,wBAAwB;AACxB;;AAEA;IACI,wBAAwB;IACxB,8BAA8B;IAC9B,kCAAkC;AACtC;;AAEA;IACI,yBAAyB;IACzB,gBAAgB;IAChB,iBAAiB;IACjB,2BAA2B;IAC3B,mCAAmC;AACvC;AACA;IACI,gBAAgB;IAChB,gBAAgB;IAChB,iBAAiB;IACjB,cAAc;IACd;AACJ","sourcesContent":[".carousel-container {\n    display: flex;\n}\n.placeholder-img {\n    display: flex;\n    width: 100%;\n    height: 500px;\n}\n\n.carousel-control-prev-icon, .carousel-control-next-icon {\n    background-color: black;\n    padding: 30px;\n    opacity: 1 !important;\n    filter: invert(1) grayscale(100);\n}\n\n.carousel-caption {\nbottom: 11rem !important;\n}\n\n.carousel-title, .carousel-text {\n    display: flex !important;\n    align-items: center !important;\n    justify-content: center !important;\n}\n\n.carousel-title{\n    text-transform: uppercase;\n    margin-left: 20%;\n    margin-right: 20%;\n    margin-bottom: 0 !important;\n    background-color: rgba(0, 0, 0, .5);\n}\n.carousel-text {\n    font-size: large;\n    margin-left: 10%;\n    margin-right: 10%;\n    height: 2.5rem;\n    background-color: rgb(177, 177, 177)\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
