// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer-container {
    position: relative;
    padding-left: 10%;
    padding-right: 10%;
    width: 100%;
    height: 15rem;
    background-color: black;
}

.info-container {
    width: 100%;
    margin-right: 100rem;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(255, 255, 255, 0.299);
}

.info {
    padding: 30px;
    display: flex;
    align-items: center;
    line-height: 1.4;
    height: 10rem;
}

.info p {
    color: white;
    font-size: 0.8rem;
    margin: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/Footer.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,iBAAiB;IACjB,kBAAkB;IAClB,WAAW;IACX,aAAa;IACb,uBAAuB;AAC3B;;AAEA;IACI,WAAW;IACX,oBAAoB;IACpB,aAAa;IACb,8BAA8B;IAC9B,mDAAmD;AACvD;;AAEA;IACI,aAAa;IACb,aAAa;IACb,mBAAmB;IACnB,gBAAgB;IAChB,aAAa;AACjB;;AAEA;IACI,YAAY;IACZ,iBAAiB;IACjB,SAAS;AACb","sourcesContent":[".footer-container {\n    position: relative;\n    padding-left: 10%;\n    padding-right: 10%;\n    width: 100%;\n    height: 15rem;\n    background-color: black;\n}\n\n.info-container {\n    width: 100%;\n    margin-right: 100rem;\n    display: flex;\n    justify-content: space-between;\n    border-bottom: 1px solid rgba(255, 255, 255, 0.299);\n}\n\n.info {\n    padding: 30px;\n    display: flex;\n    align-items: center;\n    line-height: 1.4;\n    height: 10rem;\n}\n\n.info p {\n    color: white;\n    font-size: 0.8rem;\n    margin: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
