// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.news-title::after {
    content: '';
    position: absolute;
    left: 40%;
    bottom: -20px;
    margin-left: -20px;
    opacity: 0.8;
    width: 70px;
    height: 3px;
    background: #999;
}`, "",{"version":3,"sources":["webpack://./src/components/NewsContainer.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,kBAAkB;IAClB,SAAS;IACT,aAAa;IACb,kBAAkB;IAClB,YAAY;IACZ,WAAW;IACX,WAAW;IACX,gBAAgB;AACpB","sourcesContent":[".news-title::after {\n    content: '';\n    position: absolute;\n    left: 40%;\n    bottom: -20px;\n    margin-left: -20px;\n    opacity: 0.8;\n    width: 70px;\n    height: 3px;\n    background: #999;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
