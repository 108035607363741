// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.event-container{
    width: 20rem;
    height: auto;
    justify-self: center;
}

.event-img-text {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 20%;
    background-color: rgb(110, 110, 110); /* Adjust opacity as needed */
    color: white;
    padding: 10px;
    font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/components/EventBox.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,YAAY;IACZ,oBAAoB;AACxB;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,OAAO;IACP,UAAU;IACV,oCAAoC,EAAE,6BAA6B;IACnE,YAAY;IACZ,aAAa;IACb,iBAAiB;AACrB","sourcesContent":[".event-container{\n    width: 20rem;\n    height: auto;\n    justify-self: center;\n}\n\n.event-img-text {\n    position: absolute;\n    bottom: 0;\n    left: 0;\n    width: 20%;\n    background-color: rgb(110, 110, 110); /* Adjust opacity as needed */\n    color: white;\n    padding: 10px;\n    font-weight: bold;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
