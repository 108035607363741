// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Base styles */
.header, .nav, .nav-link, .logo, .navbar-container, .number {
    display: flex !important;
    align-items: center !important;
  }
  
  /* Specific styles */
  .header {
    position: sticky !important;
    color: white !important;
    background-color: #000000 !important;
    width: 100%;
    flex-direction: column;
    text-transform: uppercase;
    font-size: large;
    height: 100px;
  }
  
  .nav {
    margin: 0 40px;
    justify-content: center !important;
  }
  
  .nav-link {
    height: 98px;
    text-decoration: none;
    color: white !important;
    transition: background-color 0.3s ease;
  
    &:hover {
      background-color: #3b3b3b;
    }
    &:not(:last-child)::after {
      content: "";
      display: inline-block;
      width: 1px;
      height: 15px;
      background-color: white;
      margin-left: 20px;
    }
  }
  
  .logo {
    width: 6rem;
    text-decoration: none;
    padding: 0px 10px 0px 0px;
  }
  
  .navbar-container {
    border-top: 2px solid white;
    padding: 0 !important;
    justify-content: space-between !important;
    position: sticky !important;
  }
  
  .number {
    font-size: x-small;
    justify-content: center !important;
  }`, "",{"version":3,"sources":["webpack://./src/components/BasicNavbar.css"],"names":[],"mappings":"AAAA,gBAAgB;AAChB;IACI,wBAAwB;IACxB,8BAA8B;EAChC;;EAEA,oBAAoB;EACpB;IACE,2BAA2B;IAC3B,uBAAuB;IACvB,oCAAoC;IACpC,WAAW;IACX,sBAAsB;IACtB,yBAAyB;IACzB,gBAAgB;IAChB,aAAa;EACf;;EAEA;IACE,cAAc;IACd,kCAAkC;EACpC;;EAEA;IACE,YAAY;IACZ,qBAAqB;IACrB,uBAAuB;IACvB,sCAAsC;;IAEtC;MACE,yBAAyB;IAC3B;IACA;MACE,WAAW;MACX,qBAAqB;MACrB,UAAU;MACV,YAAY;MACZ,uBAAuB;MACvB,iBAAiB;IACnB;EACF;;EAEA;IACE,WAAW;IACX,qBAAqB;IACrB,yBAAyB;EAC3B;;EAEA;IACE,2BAA2B;IAC3B,qBAAqB;IACrB,yCAAyC;IACzC,2BAA2B;EAC7B;;EAEA;IACE,kBAAkB;IAClB,kCAAkC;EACpC","sourcesContent":["/* Base styles */\n.header, .nav, .nav-link, .logo, .navbar-container, .number {\n    display: flex !important;\n    align-items: center !important;\n  }\n  \n  /* Specific styles */\n  .header {\n    position: sticky !important;\n    color: white !important;\n    background-color: #000000 !important;\n    width: 100%;\n    flex-direction: column;\n    text-transform: uppercase;\n    font-size: large;\n    height: 100px;\n  }\n  \n  .nav {\n    margin: 0 40px;\n    justify-content: center !important;\n  }\n  \n  .nav-link {\n    height: 98px;\n    text-decoration: none;\n    color: white !important;\n    transition: background-color 0.3s ease;\n  \n    &:hover {\n      background-color: #3b3b3b;\n    }\n    &:not(:last-child)::after {\n      content: \"\";\n      display: inline-block;\n      width: 1px;\n      height: 15px;\n      background-color: white;\n      margin-left: 20px;\n    }\n  }\n  \n  .logo {\n    width: 6rem;\n    text-decoration: none;\n    padding: 0px 10px 0px 0px;\n  }\n  \n  .navbar-container {\n    border-top: 2px solid white;\n    padding: 0 !important;\n    justify-content: space-between !important;\n    position: sticky !important;\n  }\n  \n  .number {\n    font-size: x-small;\n    justify-content: center !important;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
