// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.carousel-indicators {
    margin: 0 !important;
    position: absolute !important;
    bottom: 0 !important;
    gap: 4px;
}

.carousel-indicators button {
    background-color: gray !important;
    height: 12px !important;
    width: 12px !important;
    background-color: #bbb !important;
    border-radius: 50% !important;
}

.slide p:last-child {
    padding-bottom: 1rem;
    border-bottom: 1px solid rgba(188, 188, 188, 0.312);
}`, "",{"version":3,"sources":["webpack://./src/components/Testimonials.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,6BAA6B;IAC7B,oBAAoB;IACpB,QAAQ;AACZ;;AAEA;IACI,iCAAiC;IACjC,uBAAuB;IACvB,sBAAsB;IACtB,iCAAiC;IACjC,6BAA6B;AACjC;;AAEA;IACI,oBAAoB;IACpB,mDAAmD;AACvD","sourcesContent":[".carousel-indicators {\n    margin: 0 !important;\n    position: absolute !important;\n    bottom: 0 !important;\n    gap: 4px;\n}\n\n.carousel-indicators button {\n    background-color: gray !important;\n    height: 12px !important;\n    width: 12px !important;\n    background-color: #bbb !important;\n    border-radius: 50% !important;\n}\n\n.slide p:last-child {\n    padding-bottom: 1rem;\n    border-bottom: 1px solid rgba(188, 188, 188, 0.312);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
